import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "react-i18next"
import Section from "../../base/Section"
import { getCalendar, getLocalizedLinkToMonth } from "../../../../util/tour"
import classnames from "classnames"
import * as styles from "../../../../templates/calendar/index.module.scss"
import Link from "../../base/Link"
import SessionCard from "../../base/SessionCard"
import { CALENDAR_URL, PHOTO_TOURS_URL } from "../../../../util/constants"

const LastMinuteSection = condensed => {
  const data = useStaticQuery(query)
  const [months, setMonths] = useState([])
  const tours = data?.allStrapiTour.edges
  const { t, i18n: l } = useTranslation()
  const staticMonths = getCalendar(tours)

  useEffect(() => {
    fetch(`${process.env.GATSBY_API_URL}/tours`)
      .then(response => response.json())
      .then(resultData => {
        setMonths(getCalendar(resultData))
      })
  }, [])

  return (
    <Section
      variant={"white"}
      title={t("ui.components.extended.lastMinuteSection.title")}
      subtitle={t("ui.components.extended.lastMinuteSection.subtitle")}
      linkAll={`/${PHOTO_TOURS_URL[l.language]}/${CALENDAR_URL[l.language]}/`}
    >
      {Object.keys(months).length === 0
        ? Object.keys(staticMonths).map((key, i) => {
            let month = staticMonths[key]
            if (i === 0) {
              return (
                <div key={`photo-tour-${month.month}-${key}-${month.year}`}>
                  <div className={classnames([styles.monthWrapper])}>
                    <h3 className={classnames([styles.title])}>
                      {`${month.month} ${month.year}`}
                    </h3>
                    <div className={classnames([styles.number])}>
                      <Link
                        to={getLocalizedLinkToMonth(l, month.year, month.month)}
                      >
                        {t("templates.calendar.calendar.table.header.showAll", {
                          count: month.tours.length,
                          month: month.month,
                          year: month.year,
                        })}
                      </Link>
                    </div>
                  </div>
                  {month.tours?.map(session => {
                    return (
                      <SessionCard
                        key={`photo-tour-${i}-${session.id}`}
                        session={session.session}
                        tour={session.tour}
                        directBooking={false}
                        skeleton={false}
                      />
                    )
                  })}
                </div>
              )
            } else {
              return <></>
            }
          })
        : Object.keys(months).map((key, i) => {
            let month = months[key]
            if (i === 0) {
              return (
                <div key={`photo-tour-${month.month}-${key}-${month.year}`}>
                  <div className={classnames([styles.monthWrapper])}>
                    <h3 className={classnames([styles.title])}>
                      {`${month.month} ${month.year}`}
                    </h3>
                    <div className={classnames([styles.number])}>
                      <Link
                        to={getLocalizedLinkToMonth(l, month.year, month.month)}
                      >
                        {t("templates.calendar.calendar.table.header.showAll", {
                          count: month.tours.length,
                          month: month.month,
                          year: month.year,
                        })}
                      </Link>
                    </div>
                  </div>
                  {month.tours?.map(session => {
                    return (
                      <SessionCard
                        key={`photo-tour-${i}-${session.id}`}
                        session={session.session}
                        tour={session.tour}
                        directBooking={false}
                        skeleton={false}
                      />
                    )
                  })}
                </div>
              )
            } else {
              return <></>
            }
          })}
    </Section>
  )
}
const query = graphql`
  {
    allStrapiTour(limit: 6, sort: { order: ASC, fields: sessions___start }) {
      ...StrapiTourConnectionFragment
    }
  }
  fragment StrapiTourConnectionFragment on StrapiTourConnection {
    edges {
      node {
        id
        image {
          url
          localFile {
            ...ImageFragment
          }
        }
        slug
        states {
          slug
        }
        places {
          slug
        }
        sessions {
          end
          id
          sessionId
          start
          status
          maxPax
          balance
          price
          deposit
          currency
          users {
            id
            firstName
            instagram
            profilePicture {
              localFile {
                ...ImageFragment
              }
            }
          }
        }
        excerpt
        experienceLevel
        title
      }
    }
  }
`

export default LastMinuteSection
